import React from 'react';

const TermsOfUse = () => (
  <div className="TermsOfUse">

    <h3>海外登記簿取得サービス利用規約</h3>

    <h4>第1条　定義</h4>
    <p>1. 「当社」とは、株式会社nabeperoのことをいうものとします。</p>
    <p>2. 「本サイト」とは、海外登記簿取得エージェントのことをいうものとします。</p>
    <p>3. 「本サービス」とは、本サイトを通じて提供される、各種会社関係書類の取得代行サービスのことをいうものとします。</p>
    <p>4. 「利用者」とは、本サイト及び本サービスを利用する者のことをいうものとします。</p>
    <p>5. 「本規約」とは、海外登記簿取得代行サービス利用規約のことをいいます。ただし、当社が本サービス上に掲載する諸注意等（以下｢諸注意等｣といいます）が存在する場合、諸注意等は本規約の一部を構成するものとします。</p>

    <h4>第2条　本規約の適用・改定</h4>
    <p>1. 利用者が、本サイトを閲覧し、又は、本サービスの利用を申し込んだ場合、本規約の内容に同意したものとみなします。</p>
    <p>2. 本規約の内容は、利用者への事前の通知なしに必要に応じて変更することがありますので、本サービスをご利用の際には本サービス上に掲載されている最新の本規約を確認するものとします。</p>
    <p>3. 当社が前項に基づき本規約の内容変更を行った後、利用者が本サービスを利用した場合、当該利用者が本規約の変更を承諾したものとみなすものとします。</p>

    <h4>第3条　本サービスの申し込み</h4>
    <p>1. 利用者が本サービスの利用を申し込む場合、当該申し込みは、当社所定の方法により行うものとします。</p>
    <p>2. 利用者は、本サービスの利用を申し込みが完了した場合は、当該申し込みを撤回することはできないものとします。</p>
    <p>3. 当社は、利用者が本サービスの利用の申し込みを完了した場合であっても、利用者に通知することにより、本サービスの全部または一部の提供を中断・停止することができるものとします。</p>

    <h4>第4条　当社による本サイト・本サービスの提供停止</h4>
    <p>当社は、その裁量により、本サイト及び本サービスの全部または一部の提供を変更・中断・停止することができるものとします。</p>

    <h4>第5条　お支払い</h4>
    <p>利用者は、本サービスを利用するにあたり、当社所定の料金を、当社所定の方法により支払うものとします。</p>

    <h4>第6条　免責</h4>
    <p>当社は、利用者による本サイト又は本サービスの利用に関し、下記各号記載の事項について一切保証しません。</p>
    <p>1. 本サイト及び本サービスを通じて得られた情報の正確性、真実性、最新性</p>
    <p>2. 本サイト及び本サービスを通じて得られた情報が、利用者の当該情報の利用目的に適合していること</p>
    <p>3. 本サービスの提供が、利用者の希望する期限までに行われること</p>
    <h4>第7条　損害賠償</h4>
    <p>当社は、当社に故意又は重過失のない限り、利用者が本サイト又は本サービスを利用することによって被った損害を賠償する責任を負わないものとします。当社に故意又は重過失がある場合においても、当社の損害賠償金額の上限は、利用者が当社に支払った料金の合計金額を上回らないものとします。</p>

    <h4>第8条　個人情報保護</h4>
    <p>1. 当社は、利用者が本サイトまたは本サービスを利用するにあたって当社に提供した個人情報を、以下の目的の範囲内で利用するものとします。</p>
    <p> (1) 本サービスの提供</p>
    <p> (2) 当社が提供する他のサービスの案内（ＤＭ送付を含む）</p>
    <p>2. 当社は、前項の目的のために、個人情報に係る当社の業務の一部を、フィリピンに所在するnabepero Phillippines Inc.に委託することがあり、利用者はこれに同意するものとします。</p>
    <p>3. 個人情報に係る問い合わせ先は、以下の通りとします。</p>
      <p>株式会社nabepero 東京都世田谷3-23-3</p>
      <p>info@kaigai-touki.com</p>


  <h4>第9条　裁判管轄</h4>
  <p>本サイト又は本サービスの利用に係る一切の紛争の専属的合意管轄裁判所は、大津地方裁判所とします。</p>
  <p>2020年3月30日　施行・適用</p>

  </div>
)

export default TermsOfUse;