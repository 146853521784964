import React, {useState} from "react";
import {
  TopBody,
  Header,
  Footer,
  About,
  Summary,
  Slider,
  MainBody,
  Companies,
  BioGraphi,
  ContactForm
} from "components";

function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const scrollTo = (id, condition) => {
    const element = document.getElementById(id);
    window.scrollTo({
      top: element.offsetTop - 150,
      behavior: "smooth"
    });
    if (condition) {
      setShowSidebar(false);
      setShowMenu(false);
    }
  };

  return (
    <div className="App">
      <Header
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        scrollTo={scrollTo}
      />
      <TopBody scrollTo={scrollTo} />
      <About />
      <MainBody />
      <Companies />
      <BioGraphi
        scrollTo={scrollTo}
      />
      {/* <Slider /> */}
      <Summary />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
