import React, { useState } from "react";
import { ReactComponent as Logo } from "assets/images/Logo.svg";
import { MdMenu, MdClose } from "react-icons/md";
import {
  useMediaQuery,
  useWindowScroll,
  useThrottledFn
} from "beautiful-react-hooks";
import Container from "./container";
import Button from "./button";
import logo from "../assets/images/logo_1.jpg";

export const Header = ({
  showMenu,
  setShowMenu,
  showSidebar,
  setShowSidebar,
  scrollTo,
}) => {
  const isSmall = useMediaQuery("(max-width: 1024px)");
  const [scrollY, setScrollY] = useState(window.scrollY);

  useWindowScroll(
    useThrottledFn(event => {
      setScrollY(window.scrollY);
    })
  );

  return (
    <>
      <nav>
        <Container size="lg">
          <div className="navbar container">
          <div style={{ width: "260px" }}><img src={logo} /></div>
          {/* <Logo className="logo" /> */}
          <ul className="top-nav">
            <li
              className={`${scrollY < 710 && scrollY > 0 ? "active" : ""}`}
              onClick={() => scrollTo("about")}
            >
              サービス内容
            </li>
            <li
              className={`${scrollY < 2300 && scrollY > 710 ? "active" : ""}`}
              onClick={() => scrollTo("companies")}
            >
              取扱法人
            </li>
            <li
              className={`${scrollY < 2800 && scrollY > 2300 ? "active" : ""}`}
              onClick={() => scrollTo("lawyer")}
            >
              弁護士について
            </li>
            {/* <li
              className={`${scrollY < 3700 && scrollY > 2800 ? "active" : ""}`}
              onClick={() => scrollTo("testimonial")}
            >
              利用者の声
            </li> */}
            <li
              className={`${scrollY < 4300 && scrollY > 3700 ? "active" : ""}`}
              onClick={() => scrollTo("company_info")}
            >
              会社概要
            </li>
            <li
              className={`${scrollY < 4900 && scrollY > 4000 ? "active" : ""}`}
              onClick={() => scrollTo("contacts")}
            >
              <Button
                size="sm"
                text="お問い合わせ"
                handleClick={() => scrollTo("contacts")}
              />
            </li>
          </ul>
          {isSmall && (
            <MdMenu
              onClick={() => {
                setShowSidebar(true);
                setTimeout(() => {
                  setShowMenu(true);
                }, 500);
              }}
              style={{ fontSize: 35 }}
            />
          )}
        </div>
        </Container>
      </nav>
      <div className={`slide-bar ${showSidebar ? "show" : "hide"}`}>
        <MdClose
          onClick={() => {
            setShowSidebar(false);
            setShowMenu(false);
          }}
          className="close-icon"
        />
        <div>
          <ul className={showMenu ? "showMenu" : "hideMenu"}>
            <li onClick={() => scrollTo("about", true)}>サービス内容</li>
            <li onClick={() => scrollTo("companies", true)}>取扱法人</li>
            <li onClick={() => scrollTo("lawyer", true)}>弁護士について</li>
            {/* <li onClick={() => scrollTo("testimonial", true)}>利用者の声</li> */}
            <li onClick={() => scrollTo("company_info", true)}>会社概要</li>
            <li onClick={() => scrollTo("contacts", true)}>
              <Button handleClick={() => scrollTo("contacts", true)} size="sm" text={"お問い合わせ"} />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
