import React from "react";
import Container from "./container";
import Button from "./button";

export const ContactForm = () => {
  return (
    <div id="contacts" className="contactform">
 
        <Container size="sm">
         <h2 className="title">お問い合わせ</h2>

         <div className="description">
           <p>ご質問は下記のフォーム、もしくは</p>
           <a href="info@kaigai-touki.com">info@kaigai-touki.com</a>
           <p>にて受け付けております！</p>
         </div>

        <form
          action="https://formspree.io/mknreoea"
          method="POST"
          className="mainform"
        >
           <div className="input-row">
             <label className="label">お名前</label>
             <div className="input-row-two">
               <input type="text" name="lastname" placeholder="山田" />
               <input type="text" name="firstname" placeholder="太郎" />
             </div>
           </div>

           <div className="input-row">
             <label className="label">フリガナ</label>
             <div className="input-row-two">
               <input type="text" placeholder="ヤマダ" />
               <input type="text" placeholder="タロウ" />
             </div>
           </div>

           <div className="input-row">
             <label className="label">メールアドレス</label>
             <input type="email" name="_replyto" placeholder="yamadataro@gmail.com" />
           </div>

           <div className="input-row">
             <label className="label">お問合せ内容</label>
             <textarea name="message" placeholder="ご用件・ご質問"></textarea>
           </div>

           <div className="input-row button"  style={{ justifyContent: "center" }}>
             <button className="Button Button-sm" type="submit">送信する</button>
             {/* <Button text="送信する" size="sm" /> */}
           </div>
         </form>
       </Container>
    </div>
  );
};
