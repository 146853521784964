/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import Container from "./container";
import Modal from 'react-modal';
import TermsOfUse from './termsOfUse';

const Footer = () => {
  const [isOpen, openModal] = useState(false);
  return (
    <footer>
      <Modal 
        isOpen={isOpen}
        contentLabel="Minimal Modal Example"
      >
        <TermsOfUse />
        
        <button onClick={() => openModal(false)}>
          <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
        </button>
      </Modal>
      <Container size="lg">
        <div className="container footer-wrapper">
          <div>
            <ul>
              <li>
                <a href="https://nabepero.ph/" target="_blank">運営会社</a>
              </li>
              <li>
                <a onClick={openModal}>利用規約</a>
              </li>
            </ul>
          </div>
          <div>
            <p>copyright 2020 (c) nabepuro all rights reserved.</p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export { Footer };
